/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

* {
  margin: 0;
  font-family: "Montserrat", "Almarai", /* "Roboto", "Raleway", */ sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#F4F4F4";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
